<template>
	<v-tooltip v-bind="$attrs">
		<template v-slot:activator="{ on }">
			<v-btn :small="$vuetify.breakpoint.smAndDown" icon v-on="on" @click.prevent.stop="zoom">
				<v-icon :style="iconStyle">{{ icon }}</v-icon>
			</v-btn>
		</template>
		<span v-text="label" />
	</v-tooltip>
</template>

<script>
import DocumentVersionsModuleGuard from "@/mixins/ModulesGuards/Documents/DocumentVersionsModuleGuard";

export default {
	name: 'DocumentZoomButton',
	mixins: [DocumentVersionsModuleGuard],
	props: {
		direction: {
			type: String,
			required: true
		},
		fit: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		event () {
			const createEvent = (name, value = null) => { return { name, value } };

			if (this.fit) {
				const zoomValue = this.direction === 'in' ? 'width' : 'height';
				return createEvent(this.events.preview.ZOOM_FIT, zoomValue);
			}

			return this.direction === 'in' ? createEvent(this.events.preview.ZOOM_IN) : createEvent(this.events.preview.ZOOM_OUT);
		},
		icon () {
			if (this.fit) {
				return 'height';
			}
			return this.direction === 'in' ? 'zoom_in' : 'zoom_out';
		},
		label () {
			if (this.fit) {
				return this.direction === 'in' ? this.$t('documents.preview.fit_width') : this.$t('documents.preview.fit_height');
			}

			return this.direction === 'in' ? this.$t('documents.preview.zoom_in') : this.$t('documents.preview.zoom_out');
		},
		iconStyle () {
			if (! this.fit) {
				return '';
			}

			return this.direction === 'in' ? 'transform: rotate(90deg); border: 2px solid' : 'border: 2px solid';
		}
	},
	methods: {
		zoom () {
			this.eventBus.emit(this.event.name, this.event.value)
		}
	}
}
</script>
